.hero-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hi-text {
    color: #000;
    font-size: 100px;
    margin-top: -100px;
    font-family: 'Playfair Display', serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #000;
    font-size: 32px;
  }
